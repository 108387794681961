.pagination {
  display: flex;
  @include list-unstyled();
}

.page-link {
  line-height: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  color: $link-color;
  background-color: #fff;

  &:not(:first-child) {
    margin-left: 0.5rem;
  }

  svg {
    width: 40%;
    height: 40%;
  }

  &:hover {
    background: $background-light;
  }
}

.page-current {
  &, &:hover {
    color: #fff;
    background: $primary;
  }
}

.page-item {
}
